<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 12C15 12.5523 15.4477 13 16 13C16.5523 13 17 12.5523 17 12H15ZM20 8L20.8 7.4C20.6111 7.14819 20.3148 7 20 7V8ZM23 12H24C24 11.7836 23.9298 11.5731 23.8 11.4L23 12ZM22 17C22 17.5523 22.4477 18 23 18C23.5523 18 24 17.5523 24 17H22ZM0 17C0 17.5523 0.447715 18 1 18C1.55228 18 2 17.5523 2 17H0ZM15 8V12H17V8H15ZM16 9H20V7H16V9ZM19.2 8.6L22.2 12.6L23.8 11.4L20.8 7.4L19.2 8.6ZM22 12V17H24V12H22ZM2 17V7H0V17H2ZM2 7C2 6.82523 2.09745 6.56676 2.33211 6.33211C2.56676 6.09745 2.82523 6 3 6V4C2.17477 4 1.43324 4.40255 0.917893 4.91789C0.402547 5.43324 0 6.17477 0 7H2ZM3 6H14V4H3V6ZM14 6C14.1733 6 14.4555 6.06022 14.6657 6.21153C14.8249 6.32614 15 6.52592 15 7H17C17 5.87408 16.5085 5.07386 15.8343 4.58847C15.2111 4.13978 14.4934 4 14 4V6ZM15 7V8H17V7H15Z"
      :fill="getBaseColor(darkMode)"
    />
    <circle cx="5" cy="19" r="2" stroke="#0080FF" stroke-width="1.5" />
    <path
      d="M21 19C21 20.1046 20.1046 21 19 21C17.8954 21 17 20.1046 17 19C17 17.8954 17.8954 17 19 17C20.1046 17 21 17.8954 21 19Z"
      stroke="#0080FF"
      stroke-width="1.5"
    />
    <path
      d="M9.5 17.25C9.08579 17.25 8.75 17.5858 8.75 18C8.75 18.4142 9.08579 18.75 9.5 18.75V17.25ZM14.5 18.75C14.9142 18.75 15.25 18.4142 15.25 18C15.25 17.5858 14.9142 17.25 14.5 17.25V18.75ZM9.5 18.75H14.5V17.25H9.5V18.75Z"
      :fill="getBaseColor(darkMode)"
    />
  </svg>
</template>
